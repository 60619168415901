<template>
  <div class="dashboard-content">
    <div class="row">
      <div class="col-12 text-center">
        <h3>Galería de Archivos de {{ company.name }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <div class="card" style="padding: 20px">
          <h4 style="margin-bottom: 20px">Imágenes</h4>
          <v-row>
            <v-col
              v-for="image in company.images"
              :key="image.id"
              class="d-flex child-flex"
              cols="3"
            >
              <v-img
                :src="image.route"
                alt=""
                :lazy-src="image.route"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-1" align="center" justify="center">
                    <v-progress-circular
                      class="mx-auto"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                title="Eliminar archivo"
                @click="deleteFile(image.id)"
                style="cursor: pointer"
                class="round-pill like-banner d-block bg-primary action-span"
                ><i class="fa fa-remove"></i
              ></span>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <div class="card" style="padding: 20px">
          <h4 style="margin-bottom: 20px">Infografías</h4>
          <v-row>
            <v-col v-for="image in company.infographics" :key="image.id" cols="6">
              <v-img
                :src="image.route"
                :alt="`image`"
                :lazy-src="image.route"
                :aspect-ratio="800 / 300"
                class="grey lighten-2 infographic"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-1" align="center" justify="center">
                    <v-progress-circular
                      class="mx-auto"
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                title="Eliminar archivo"
                @click="deleteFile(image.id)"
                style="cursor: pointer"
                class="round-pill like-banner d-block bg-primary action-span"
                ><i class="fa fa-remove"></i
              ></span>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12 text-center">
        <div class="card" style="padding: 0px 5%; height: 100%">
          <h4 style="margin-bottom: 20px">Logo</h4>
          <v-col
            class="d-flex child-flex col-centered mx-auto"
            style=""
            cols="8"
            v-if="company.logo"
          >
            <v-img
              :src="company.logo.route"
              :lazy-src="``"
              :alt="'logo'"
              aspect-ratio="3"
              contain
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-3" align="center" justify="center">
                  <v-progress-circular
                    class="mx-auto"
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <span
              title="Eliminar archivo"
              @click="deleteFile(company.logo.id)"
              style="cursor: pointer"
              class="round-pill like-banner d-block bg-primary action-span"
              ><i class="fa fa-remove"></i
            ></span>
          </v-col>
          <v-col class="d-flex child-flex" cols="12" v-else>
            <p>No se ha cargado el Logo</p>
          </v-col>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 text-center">
        <div class="card" style="padding: 0px 5%; height: 100%">
          <h4 style="margin-bottom: 20px">Logo de Portada</h4>
          <v-col
            class="d-flex child-flex col-centered mx-auto"
            style=""
            cols="8"
            v-if="company.isotype"
          >
            <v-img
              :src="company.isotype.route"
              :lazy-src="``"
              :alt="'isotipo'"
              aspect-ratio="3"
              contain
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-3" align="center" justify="center">
                  <v-progress-circular
                    class="mx-auto"
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <span
              title="Eliminar archivo"
              @click="deleteFile(company.isotype.id)"
              style="cursor: pointer"
              class="round-pill like-banner d-block bg-primary action-span"
              ><i class="fa fa-remove"></i
            ></span>
          </v-col>
          <v-col class="d-flex child-flex" cols="12" v-else>
            <p>No se ha cargado el archivo</p>
          </v-col>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 text-center">
        <div class="card" style="padding: 0px 5%; height: 100%">
          <h4 style="margin-bottom: 20px">Imagen de Portada</h4>
          <v-col
            class="d-flex child-flex col-centered mx-auto"
            style=""
            cols="8"
            v-if="company.cover"
          >
            <v-img
              :src="company.cover.route"
              :lazy-src="``"
              :alt="'logo'"
              aspect-ratio="3"
              contain
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-3" align="center" justify="center">
                  <v-progress-circular
                    class="mx-auto"
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <span
              title="Eliminar archivo"
              @click="deleteFile(company.cover.id)"
              style="cursor: pointer"
              class="round-pill like-banner d-block bg-primary action-span"
              ><i class="fa fa-remove"></i
            ></span>
          </v-col>
          <v-col class="d-flex child-flex" cols="12" v-else>
            <p>No se ha cargado la imagen de portada</p>
          </v-col>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 text-center">
        <div class="card" style="padding: 0px 5%; height: 100%">
          <h4 style="margin-bottom: 20px">COF</h4>
          <div
            class="col-lg-6 col-centered text-center mx-auto"
            style="max-width: 150px"
            v-if="company.cof"
          >
            <iframe :src="pdfSource" style="width: 750px; height: 700px" frameborder="0">
            </iframe>
            <span
              title="Eliminar archivo"
              @click="deleteFile(image.id)"
              style="cursor: pointer"
              class="round-pill like-banner d-block bg-primary action-span"
              ><i class="fa fa-remove"></i
            ></span>
          </div>
          <div class="col-lg-12 col-centered text-center" v-else>
            <p>No se ha cargado la COF</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card add-listing-section">
          <h6 class="mx-auto">Subir Logo</h6>
          <!-- Dropzone -->
          <dropzone
            ref="dropzoneLogo"
            id="dropzoneLogo"
            v-on:vdropzone-canceled="canceledLogoUpload"
            v-on:vdropzone-success="successLogoUpload"
            v-on:vdropzone-sending="sendingLogoEvent"
            v-on:vdropzone-total-upload-progress="uploadProgress"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneLogoOptions"
            :useCustomSlot="false"
          >
            <div class="dropzone-custom-content">
              <div class="subtitle">
                Arrastra y suelta, o haz clic para seleccionar un logo de tu dispositivo
              </div>
            </div>
          </dropzone>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card add-listing-section">
          <h6 class="mx-auto">Subir Logo de Portada</h6>
          <!-- Dropzone -->
          <dropzone
            ref="dropzoneIsotype"
            id="dropzoneIsotype"
            v-on:vdropzone-canceled="canceledIsotypeUpload"
            v-on:vdropzone-success="successIsotypeUpload"
            v-on:vdropzone-sending="sendingIsotypeEvent"
            v-on:vdropzone-total-upload-progress="uploadProgress"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneIsotypeOptions"
            :useCustomSlot="false"
          >
            <div class="dropzone-custom-content">
              <div class="subtitle">
                Arrastra y suelta, o haz clic para seleccionar un isotipo de tu
                dispositivo
              </div>
            </div>
          </dropzone>
          <span style="font-size: 15px">El archivo se redimencionará a 100x100px</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card add-listing-section">
          <h6 class="mx-auto">Subir Imagen de Portada</h6>
          <!-- Dropzone -->
          <dropzone
            ref="dropzoneCover"
            id="dropzoneCover"
            v-on:vdropzone-canceled="canceledLogoUpload"
            v-on:vdropzone-success="successCoverUpload"
            v-on:vdropzone-sending="sendingCoverEvent"
            v-on:vdropzone-total-upload-progress="uploadProgress"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneCoverOptions"
            :useCustomSlot="false"
          >
            <div class="dropzone-custom-content">
              <div class="subtitle">
                Arrastra y suelta, o haz clic para seleccionar un logo de tu dispositivo
              </div>
            </div>
          </dropzone>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card add-listing-section">
          <h6 class="mx-auto">Subir Imágenes</h6>
          <!-- Dropzone -->
          <dropzone
            ref="dropzoneImage"
            id="dropzoneImage"
            v-on:vdropzone-canceled="canceledImageUpload"
            v-on:vdropzone-success="successImageUpload"
            v-on:vdropzone-sending="sendingImageEvent"
            v-on:vdropzone-total-upload-progress="uploadProgress"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneImageOptions"
            :useCustomSlot="false"
          >
            <div class="dropzone-custom-content">
              <div class="subtitle">
                Arrastra y suelta, o haz clic para seleccionar una imagen de tu
                dispositivo
              </div>
            </div>
          </dropzone>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card add-listing-section">
          <h6 class="mx-auto">Subir Infografías</h6>
          <!-- Dropzone -->
          <dropzone
            ref="dropzoneInfographic"
            id="dropzoneInfographic"
            v-on:vdropzone-canceled="canceledInfographicUpload"
            v-on:vdropzone-success="successInfographicUpload"
            v-on:vdropzone-sending="sendingInfographicEvent"
            v-on:vdropzone-total-upload-progress="uploadProgress"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneInfographicOptions"
            :useCustomSlot="false"
          >
            <div class="dropzone-custom-content">
              <div class="subtitle">
                Arrastra y suelta, o haz clic para seleccionar una imagen de tu
                dispositivo
              </div>
            </div>
          </dropzone>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="card add-listing-section">
          <h6 class="mx-auto">Subir COF</h6>
          <!-- Dropzone -->
          <dropzone
            ref="dropzoneCOF"
            id="dropzoneCOF"
            v-on:vdropzone-canceled="canceledCOFUpload"
            v-on:vdropzone-success="successCOFUpload"
            v-on:vdropzone-sending="sendingCOFEvent"
            v-on:vdropzone-total-upload-progress="uploadProgress"
            v-on:vdropzone-error="uploadError"
            :options="dropzoneCOFOptions"
            :useCustomSlot="false"
          >
            <div class="dropzone-custom-content">
              <div class="subtitle">
                Arrastra y suelta, o haz clic para seleccionar un archivo PDF de tu
                dispositivo
              </div>
            </div>
          </dropzone>
        </div>
      </div>
    </div>

    <div class="row">
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            dark
            @click="$router.go(-1)"
          >
            Regresar
          </v-btn>
        </div>
      </v-col>
    </div>

    <!-- Section / End -->
  </div>
</template>
<script>
  import Dropzone from 'vue2-dropzone'
  export default {
    name: 'CompanyGalery',
    components: {
      Dropzone,
    },
    data() {
      return {
        company: {},
        error: '',
        pdfSource: '',
        dropzoneImageOptions: {
          url: process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.query.id,
          method: 'POST',
          thumbnailWidth: 100,
          addRemoveLinks: true,

          acceptedFiles: '.jpg, .jpeg, .png, .gif',
          dictDefaultMessage:
            "<i class='fa fa-cloud-upload'></i>Arrastra y suelta, o haz clic para seleccionar un archivo de tu dispositivo",
          maxFilesize: 0.3,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization:
              'Bearer ' +
              JSON.parse(localStorage.getItem('vue-session-key')).tokenSession,
          },
        },
        dropzoneInfographicOptions: {
          url: process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.query.id,
          method: 'POST',
          thumbnailWidth: 100,
          addRemoveLinks: true,
          acceptedFiles: '.jpg, .jpeg, .png, .gif',
          dictDefaultMessage:
            "<i class='fa fa-cloud-upload'></i>Arrastra y suelta, o haz clic para seleccionar un archivo de tu dispositivo",
          maxFilesize: 0.3,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization:
              'Bearer ' +
              JSON.parse(localStorage.getItem('vue-session-key')).tokenSession,
          },
        },
        dropzoneLogoOptions: {
          url: process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.query.id,
          method: 'POST',
          thumbnailWidth: 100,
          addRemoveLinks: true,
          acceptedFiles: '.svg, .jpg, .jpeg, .png',
          dictDefaultMessage:
            "<i class='fa fa-cloud-upload'></i>Arrastra y suelta, o haz clic para seleccionar un archivo de tu dispositivo",
          maxFilesize: 0.2,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization:
              'Bearer ' +
              JSON.parse(localStorage.getItem('vue-session-key')).tokenSession,
          },
        },
        dropzoneIsotypeOptions: {
          url: process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.query.id,
          method: 'POST',
          thumbnailWidth: 100,
          addRemoveLinks: true,
          acceptedFiles: '.svg, .jpg, .jpeg, .png',
          dictDefaultMessage:
            "<i class='fa fa-cloud-upload'></i>Arrastra y suelta, o haz clic para seleccionar un archivo de tu dispositivo",
          maxFilesize: 0.2,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization:
              'Bearer ' +
              JSON.parse(localStorage.getItem('vue-session-key')).tokenSession,
          },
        },
        dropzoneCoverOptions: {
          url: process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.query.id,
          method: 'POST',
          thumbnailWidth: 100,
          addRemoveLinks: true,
          acceptedFiles: '.jpg, .jpeg, .png',
          dictDefaultMessage:
            "<i class='fa fa-cloud-upload'></i>Arrastra y suelta, o haz clic para seleccionar un archivo de tu dispositivo",
          maxFilesize: 0.2,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization:
              'Bearer ' +
              JSON.parse(localStorage.getItem('vue-session-key')).tokenSession,
          },
        },
        dropzoneCOFOptions: {
          url: process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.query.id,
          method: 'POST',
          acceptedFiles: '.pdf',
          thumbnailWidth: 100,
          addRemoveLinks: true,
          dictDefaultMessage:
            "<i class='fa fa-cloud-upload'></i>Arrastra y suelta, o haz clic para seleccionar un archivo de tu dispositivo",
          maxFilesize: 5,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization:
              'Bearer ' +
              JSON.parse(localStorage.getItem('vue-session-key')).tokenSession,
          },
        },
      }
    },
    created() {
      this.getCompany()
    },
    methods: {
      async getCompany() {
        this.loading = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.query.id,
            this.config
          )
          .then((response) => {
            this.company = response.data
            this.$refs.dropzoneImage.setOption(
              'url',
              process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.company.id
            )
            Object.assign(this.company, {
              images: this.company.uploads.filter((file) => file.type == 'image'),
            })
            Object.assign(this.company, {
              infographics: this.company.uploads.filter(
                (file) => file.type == 'infographic'
              ),
            })
            Object.assign(this.company, {
              cover: this.company.uploads.filter((file) => file.type == 'cover').shift(),
            })
            Object.assign(this.company, {
              isotype: this.company.uploads
                .filter((file) => file.type == 'small-logo')
                .shift(),
            })
            Object.assign(this.company, {
              cof: this.company.uploads.filter((file) => file.type == 'cof').shift(),
            })
            if (this.company.cof) {
              this.pdfSource =
                'https://docs.google.com/gview?url=' +
                this.company.cof.route +
                '&embedded=true'
            } else {
              this.pdfSource = 'https://docs.google.com/gview?url=&embedded=true'
            }

            this.loading = false
          })
      },
      setUrlImage() {
        this.$refs.dropzoneImage.setOption(
          'url',
          process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.company.id
        )
      },
      sendingImageEvent(file, xhr, formData) {
        formData.append('fileType', 'image')
        formData.append('fileResize[width]', 600)
        formData.append('fileResize[height]', 350)
        formData.append('_method', 'patch')
      },
      sendingInfographicEvent(file, xhr, formData) {
        formData.append('fileType', 'infographic')
        formData.append('fileResize[width]', 800)
        formData.append('fileResize[height]', 300)
        formData.append('_method', 'patch')
      },
      sendingCoverEvent(file, xhr, formData) {
        if (this.company.cover) {
          formData.append('fileId', this.company.cover.id)
        }
        formData.append('fileType', 'cover')
        formData.append('fileResize[width]', 370)
        formData.append('fileResize[height]', 220)
        formData.append('_method', 'patch')
      },
      sendingIsotypeEvent(file, xhr, formData) {
        if (this.company.isotype) {
          formData.append('fileId', this.company.isotype.id)
        }
        formData.append('fileType', 'small-logo')
        formData.append('fileResize[width]', 100)
        formData.append('fileResize[height]', 100)
        formData.append('_method', 'patch')
      },
      sendingLogoEvent(file, xhr, formData) {
        if (this.company.logo) {
          formData.append('fileId', this.company.logo.id)
        }
        formData.append('fileType', 'logo')
        formData.append('_method', 'patch')
      },
      sendingCOFEvent(file, xhr, formData) {
        if (this.company.cof) {
          formData.append('fileId', this.company.cof.id)
        }
        formData.append('fileType', 'cof')
        formData.append('_method', 'patch')
      },
      uploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {},
      canceledImageUpload(file) {
        this.$refs.dropzoneImage.removeFile(file)
        this.$emit('vdropzone-file-added', file)
      },
      canceledInfographicUpload(file) {
        this.$refs.dropzoneInfographic.removeFile(file)
        this.$emit('vdropzone-file-added', file)
      },
      canceledCoverUpload(file) {
        this.$refs.dropzoneCover.removeFile(file)
        this.$emit('vdropzone-file-added', file)
      },
      canceledIsotypeUpload(file) {
        this.$refs.dropzoneIsotype.removeFile(file)
        this.$emit('vdropzone-file-added', file)
      },
      canceledLogoUpload(file) {
        this.$refs.dropzonelogo.removeFile(file)
        this.$emit('vdropzone-file-added', file)
      },
      canceledCOFUpload(file) {
        this.$refs.dropzoneCOF.removeFile(file)
        this.$emit('vdropzone-file-added', file)
      },
      successLogoUpload(file, response) {
        this.getCompany()
        this.$refs.dropzoneLogo.removeFile(file)
      },
      successCOFUpload(file, response) {
        this.getCompany()
        this.$refs.dropzoneCOF.removeFile(file)
      },
      successImageUpload(file, response) {
        this.getCompany()
        this.$refs.dropzoneImage.removeFile(file)
      },
      successInfographicUpload(file, response) {
        this.getCompany()
        this.$refs.dropzoneInfographic.removeFile(file)
      },
      successCoverUpload(file, response) {
        this.getCompany()
        this.$refs.dropzoneCover.removeFile(file)
      },
      successIsotypeUpload(file, response) {
        this.getCompany()
        this.$refs.dropzoneIsotype.removeFile(file)
      },
      uploadError(file, message, xhr) {
        this.error = message
        if (message.message) {
          this.$swal({
            icon: 'error',
            title: `Error en la carga del archivo ${file.name}`,
            html: `Mensaje: ${message.message} <br /> Por favor, intente nuevamente.`,
            showCancelButton: false,
            showConfirmButton: true,
          })
        } else {
          this.$swal({
            icon: 'error',
            title: `Error en la carga del archivo ${file.name}`,
            html: `Mensaje: ${message} <br /> Por favor, intente nuevamente.`,
            showCancelButton: false,
            showConfirmButton: true,
          })
        }
      },
      async deleteFile(id) {
        this.loading = true
        axios
          .delete(
            process.env.VUE_APP_API_DIRECTORY + 'upload-management/delete-upload/' + id,
            this.config
          )
          .then((response) => {
            this.getCompany()
            this.loading = false
            this.$swal({
              icon: 'success',
              title: `Eliminado`,
              showCancelButton: false,
              showConfirmButton: true,
            })
          })
      },
    },
  }
</script>
<style lang="scss"></style>
